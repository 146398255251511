import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@base-component';
import { IBaseTemplate, ITag } from '@interfaces';
import { NbDialogRef, NbDialogService, NbPopoverDirective } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { App } from '@root/state/app.actions';
import { IAppStateModel } from '@root/state/app.model';
import { EventQueueService, WebsocketService } from '@services';
import { Observable } from 'rxjs';

@Component({
  selector: 'resplendent-copy-to-template-dialog',
  templateUrl: './copy-to-template-dialog.component.html',
  styleUrls: ['./copy-to-template-dialog.component.scss'],
})
export class CopyToTemplateDialogComponent extends BaseComponent implements OnInit {
  @Input() id: string;
  @Input() type: 'dashboard' | 'widget' | 'table' | 'data_modifier';
  @Input() name: string;
  @ViewChild(NbPopoverDirective) newTagPopover: NbPopoverDirective;
  typeLabels = {
    dashboard: 'dashboard',
    widget: 'widget',
    data_modifier: 'modified dataset',
    table: 'dataset',
  };
  template: Partial<IBaseTemplate> = {
    label: '',
    description: '',
    tags: [],
    tagIds: [],
  };
  tagOptions: Observable<ITag[]> = this.store.select((state) => (state.app as IAppStateModel).appState.tagOptions.tags);

  templateExists = false;
  constructor(
    private dialogRef: NbDialogRef<CopyToTemplateDialogComponent>,
    private websocketService: WebsocketService,
    private eventQueue: EventQueueService,
    private store: Store,
    private dialogService: NbDialogService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.checkForExistingTemplate();
    this.loadTagOptions();
  }

  async loadTagOptions() {
    this.store.dispatch(new App.GetTagOptions());
  }
  async checkForExistingTemplate() {
    const { res, success } = await this.websocketService.asyncRequest('GET_TEMPLATE_FROM_PARENT_ID', {
      type: this.type,
      id: this.id,
    });
    if (success && res.template !== null) {
      this.templateExists = true;
      this.template = res.template;
    }
  }
  async submit() {
    if (!this.isValid()) {
      return;
    }
    this.startLoading();
    const { res, success } = await this.websocketService.asyncRequest('COPY_TO_TEMPLATE', {
      type: this.type,
      id: this.id,
      tags: this.template.tagIds,
      label: this.template.label,
      description: this.template.description,
    });
    this.stopLoading();
    if (success) {
      if (res.finished){
        this.eventQueue.dispatch('SHOW_TOAST', {
          status: 'success',
          title: 'Yay!',
          message: `Successfully ${this.templateExists ? 'updated' : 'created'} the template.`,
        });
        this.dialogRef.close(res);
      } else {
        this.eventQueue.dispatch('SHOW_TOAST', {
          status: 'info',
          title: 'You are missing some items that this template depends on!',
          message: 'Please create them as templates in the dialogs that have opened.',
          duration: 10000,
        })
        for (let item of res.neededItems.sort((a,b) => a.order > b.order ? -1: 1)){
          this.dialogService.open(CopyToTemplateDialogComponent, {context: {id: item.id, type: item.type, name: item.name}})
        }
      }
      
    }
  }
  isValid() {
    if (this.template.label === '') {
      return false;
    }
    if (this.template.description === '') {
      return false;
    }
    return true;
  }
  cancel() {
    this.dialogRef.close();
  }
}
