<nb-card>
  <nb-card-body class="main-contain">
    <ng-container *ngIf="!templateExists; else itExists">
      <h6>
        Use this {{ typeLabels[type] }} as a template: <span class="object-name">{{ name }}</span>
      </h6>
    </ng-container>
    <ng-template #itExists>
      <h6>
        Update this {{ typeLabels[type] }} template: <span class="object-name">{{ name }}</span>
      </h6>
      <label class="label">
        This {{ typeLabels[type] }} already exists as a template; saving it will update the template.
      </label>
      <label class="label" *ngIf="type === 'dashboard'">
        This will also update all of the widget templates that are children of this dashboard template.
      </label>
    </ng-template>
    <br />

    <label class="label">Template Name</label>
    <input type="text" nbInput [(ngModel)]="template.label" fullWidth />
    <br />

    <label class="label">Description</label>
    <textarea
      class="description"
      nbInput
      placeholder="Help your future self remember what this template is for."
      fullWidth
      autocomplete="new-password"
      [(ngModel)]="template.description"
    ></textarea>
    <br />
    <label class="label">Tags</label>
    <resplendent-select [(selected)]="template.tagIds" multi class="tag-selector">
      <resplendent-select-option *ngFor="let tag of tagOptions | async" [value]="tag.id">
        {{ tag.label }}
      </resplendent-select-option>
    </resplendent-select>
    <button nbButton ghost [nbPopover]="newTagPopoverTemp" nbPopoverTrigger="click">new tag</button>
    <ng-template #newTagPopoverTemp>
      <resplendent-new-tag-dialog
        (tagAdded)="loadTagOptions(); !!$event && this.template.tagIds.push($event); this.newTagPopover.hide()"
      >
      </resplendent-new-tag-dialog>
    </ng-template>
    <br />
    <br />

    <div class="buttons-container">
      <button nbButton status="basic" (click)="cancel()">Cancel</button>
      <button nbButton status="primary" (click)="submit()" [nbSpinner]="isLoading" [disabled]="!isValid()">
        <ng-container *ngIf="templateExists"> Update Template </ng-container>
        <ng-container *ngIf="!templateExists"> Create Template </ng-container>
      </button>
    </div>
  </nb-card-body>
</nb-card>
